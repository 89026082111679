<template>
  <main>
    <form @submit.prevent="handleSubmit" class="shadow edit-form">
      <h4>Create New Building</h4>
      <div class="form-outline mb-4">
        <label>Building Name</label>
        <input
          type="text"
          required
          v-model="buildingName"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Building Type</label>
        <input
          type="text"
          required
          v-model="buildingType"
          class="form-control"
        />
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary btn-block">
            Create
          </button>
        </div>
        <div class="col">
          <router-link :to="{ name: 'Buildings' }" class="btn btn-secondary">
            Cancel
          </router-link>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary btn-block" disabled>
          Creating...
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import useCollection from "@/composables/useCollection";
import { ref } from "@vue/reactivity";
import getUser from "@/composables/getUser";
import { timestamp } from "@/firebase/config";
import { useRouter } from "vue-router";

export default {
  setup() {
    const buildingName = ref("");
    const buildingType = ref("");
    const isPending = ref(false);
    const formError = ref(null);
    const { user } = getUser();
    const router = useRouter();

    const { error: errorAddDoc, addDoc: addBuilding } = useCollection(
      "buildings"
    );

    const { error: errorSetRentRoll, setDoc: setRentRoll } = useCollection(
      "rentrolls"
    );

    const handleSubmit = async () => {
      isPending.value = true;
      formError.value = null;
      const buildingRef = await addBuilding({
        name: buildingName.value,
        type: buildingType.value,
        managerId: user.value.uid,
        active: false,
        supportEmail: user.value.email,
        maxUsqft: 0,
        createdAt: timestamp()
      });
      isPending.value = false;
      if (errorAddDoc.value) {
        formError.value = errorAddDoc.value;
      } else {
        console.log("Offering Added");
        await setRentRoll(buildingRef.id, {
          managerId: user.value.uid,
          createdAt: timestamp()
        });
        if (errorSetRentRoll.value) {
          formError.value = errorSetRentRoll.value;
        } else {
          router.push({
            name: "BuildingDetails",
            params: { buildingId: buildingRef.id }
          });
        }
      }
    };

    return { handleSubmit, buildingName, buildingType, formError, isPending };
  }
};
</script>

<style>
</style>